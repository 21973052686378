import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
// import { DataService } from '../services/data.service';
// import { ToastrService } from 'ngx-toastr';
//import { UserService } from '../services/user.service';
import { LoginService } from '../services/loginService';
import { Login } from '../models/login';

import { Role } from '../models/role';
import { Company } from '../models/company';
import { ReportFilter, Globle } from '../Globle';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  ngOnInit() {
    // document.getElementById("loader1").style.display  ="inline"
    // this.myFunction()
   }
  loading : boolean
  newYearFlag : boolean = true;
  role: Role;
  username: any = '';
  password: any = '';
  societyCode: any = '';
  loginData: any
  loginForm: any;
  loginCheck = false;
  public errorMessage: string = '';
  customSpinner: boolean = false;
  alertBox: boolean = false;
  locales = [];
  currentUrl = "";
  login: Login = new Login();
  companyArr: Company[];
  validate: boolean;
  userArr: Login[];

  reportFilter: ReportFilter = new ReportFilter();
  constructor(private fb: FormBuilder,
    private route: Router,
    private ngxService: NgxUiLoaderService,
    private toastr: ToastrService,
    private loginService: LoginService,  private sanitizer: DomSanitizer) {
    // sessionStorage.clear();
    // document.getElementById("loader1").style.display  ="inline"
    this.loginForm = fb.group({
      user: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      societyCode: ['2145', Validators.required]
    });
    // this.myFunction()

  }
  msg: any;
  resp;
  get f() { return this.loginForm.controls; }

  async userLogin() {
    // // console.log("sdasdgb")
    //  //this.toastr.error( 'Login Failed!');
    //  this.ngxService.start();
    // this.route.navigate(['/pages/welcome']);
    try {
      this.loading = true;
      this.ngxService.start();
      //  if (this.validate) {
      this.username = this.loginForm.value.user;
      this.password = this.loginForm.value.password;
      this.societyCode = this.loginForm.value.societyCode
      //this.loginData = { "username": this.username, "password": this.password }
      this.login.loginId = this.username;
      this.login.password = this.password;

      this.loginService.userLogin(this.societyCode, this.login.loginId, this.login.password).subscribe(response => {
        this.resp = response[0];

        // console.log(this.resp);
        sessionStorage.setItem('payload', JSON.stringify(this.resp));
        // if(this.resp!=null)
        if (this.resp==null) {
          alert("Wrong Password !!")
          this.loading = false;
        } else {
          // this.loading = false
          // setTimeout(this.showPage, 3000);
          //this.route.navigate(['/pages/welcome']);
          if (this.resp.roleId == 1001 || this.resp.roleId == 1000 ) {
            this.ngxService.stop();
            this.route.navigate(['/pages/masters/dashboard']);
          } else if (this.resp.roleId == 1002) {
            this.ngxService.stop();
            this.route.navigate(['/pages/demand/view-demand']);
          }else if (this.resp.roleId == 1003) {
            this.ngxService.stop();
            this.route.navigate(['/pages/expenditure/collection-report']);
            // this.route.navigate(['/pages/masters/dashboard']);
          }
          this.loading = false
        }
      
      })

    }
    catch (e) {
      console.log(e);
    }
    finally {

    }
  }
  
  getCompanylogo(companyId){
    var companyLogo
    this.loginService.getCompanyLogo(companyId).subscribe((
      response =>{
        companyLogo = response
        let objectURL = 'data:image/jpeg;base64,' + companyLogo.image;
        Globle.imageBlobUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);  
        //this.img = Globle.imageBlobUrl
      }
    ))
  }

  getCompany() {
    var utc = new Date().toJSON().slice(0, 10)
    let comp = parseInt(this.loginForm.value.companyId);
    // console.log("comp", comp);

    this.loginService.searchCompanyId(comp).subscribe(response => {
      this.companyArr = response
      if (this.companyArr.length > 0)
        if (utc < this.companyArr[0].licence_Exp_Date) {
          this.validate = true;
        }
        else {
          alert("Company Licence is Expired")
          this.validate = false
        }
      if (this.companyArr.length == 0) {
        alert("Company Code is Invalid")
      }
    })

  }
  forgot(){
    this.route.navigate(['/pages/masters/forgot-password']);
  }
  getLoginId() {
    var utc = new Date().toJSON().slice(0, 10)
    let userId = this.loginForm.value.user
    this.loginService.searchLoginId(userId).subscribe(response => {
      this.userArr = response;
      if (this.userArr.length > 0)
        if (this.userArr[0].status == false) {
          alert("LoginId is Inactive")
          this.validate = false;
        }
        else {
          this.validate = true;
        }

      if (this.userArr.length == 0) {
        alert("LoginId is Invalid")
        this.validate = false
      }
    })

  }
  myVar
  myFunction() {
    this.myVar = setTimeout(this.showPage, 3000);
  }
  
   showPage() {
    // document.getElementById("loader1").style.display = "none";
    // document.getElementById("myDiv").style.display = "block";
  }

}

import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Login } from "../models/login";
import { environment } from '../../environments/environment';
import { Company } from "../models/company";
import { Movement } from "../models/movement";
import { Observable } from 'rxjs';

@Injectable(
  {
    providedIn: 'root'
  }
)

export class LoginService {
  constructor(private http: HttpClient) { }

  private reqHeader = new HttpHeaders({
    "Access-Control-Allow-Origin":"*",
    "Access-Control-Allow-Methods":"GET, POST",
    "Content-Type":"application/json",
});


  userLogin(societyCode, userId, pwd) {
    return this.http.get<Login[]>(`${environment.apiUrl}/society/userlogin/findlogin/` + societyCode + `/` + userId + `/` + pwd,{ headers: this.reqHeader}).pipe(map(res => res))
  }

  searchCompanyId(term) {
    return this.http.get<Company[]>(`${environment.apiUrl}/fams/company/find12/` + term +'/true' ,{ headers: this.reqHeader}).pipe(map(res => res))
  }

  searchLoginId(term) {
    return this.http.get<Login[]>(`${environment.apiUrl}/fams/userlogin/finduser/` + term ,{ headers: this.reqHeader}).pipe(map(res => res))
  }
  checkOnLogin(companyId,employeeId){
    return this.http.get<Movement[]>(`${environment.apiUrl}/fams/movement/managerLogin/` + companyId+"/" +employeeId,{ headers: this.reqHeader}).pipe(map(res => res))
  }
  checkOnLoginByDesg(companyId,employeeId){
    return this.http.get(`${environment.apiUrl}/fams/movement/managerLogin/` + companyId+"/" +employeeId,{ headers: this.reqHeader}).pipe(map(res => res))
  }
  getForms(reportFilter: Object): Observable<Object> {
    var obj=this.http.post(`${environment.apiUrl}/fams/formauthentication/getforms`, reportFilter,{ headers: this.reqHeader});
        return obj;
  }
  getCompanyLogo(companyId){
    return this.http.get<Movement[]>(`${environment.apiUrl}/fams/company/companylogo/` + companyId,{ headers: this.reqHeader})
  }
  logOut(societyCode, userId, pwd){
    return this.http.get<Login[]>(`${environment.apiUrl}/society/userlogin/logOut/` + societyCode + `/` + userId + `/` + pwd,{ headers: this.reqHeader})
  }
  getLogedInUsers(societyCode) {
    return this.http.get<Login[]>(`${environment.apiUrl}/society/userlogin/findlogedin/${societyCode}`,{ headers: this.reqHeader});
    }
}



import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { Globle } from '../../../Globle';
import { LoginService } from '../../../services/loginService';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
// export class HeaderComponent implements OnInit, OnDestroy {

//   private destroy$: Subject<void> = new Subject<void>();
//   userPictureOnly: boolean = false;
//   user: any;

//   themes = [
//     {
//       value: 'default',
//       name: 'Light',
//     },
//     {
//       value: 'dark',
//       name: 'Dark',
//     },
//     {
//       value: 'cosmic',
//       name: 'Cosmic',
//     },
//     {
//       value: 'corporate',
//       name: 'Corporate',
//     },
//   ];

//   currentTheme = 'default';

//   userMenu = [ { title: 'Profile' }, { title: 'Log out' } ];

//   constructor(private sidebarService: NbSidebarService,
//               private menuService: NbMenuService,
//               private themeService: NbThemeService,
//               private userService: UserData,
//               private layoutService: LayoutService,
//               private breakpointService: NbMediaBreakpointsService) {
//   }

//   ngOnInit() {
//     this.currentTheme = this.themeService.currentTheme;

//     this.userService.getUsers()
//       .pipe(takeUntil(this.destroy$))
//       .subscribe((users: any) => this.user = users.nick);

//     const { xl } = this.breakpointService.getBreakpointsMap();
//     this.themeService.onMediaQueryChange()
//       .pipe(
//         map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
//         takeUntil(this.destroy$),
//       )
//       .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

//     this.themeService.onThemeChange()
//       .pipe(
//         map(({ name }) => name),
//         takeUntil(this.destroy$),
//       )
//       .subscribe(themeName => this.currentTheme = themeName);
//   }

//   ngOnDestroy() {
//     this.destroy$.next();
//     this.destroy$.complete();
//   }

//   changeTheme(themeName: string) {
//     this.themeService.changeTheme(themeName);
//   }

//   toggleSidebar(): boolean {
//     this.sidebarService.toggle(true, 'menu-sidebar');
//     this.layoutService.changeLayoutSize();

//     return false;
//   }

//   navigateHome() {
//     this.menuService.navigateHome();
//     return false;
//   }
// }

export class HeaderComponent implements OnInit, OnDestroy {

  currentTheme = 'default';
  logout: any;
  userPicture = "../../../../assets/images/no-user.png";
  userMenu = [{ title: 'Logout' }];
  name

  constructor(private menuService: NbMenuService,
    private sidebarService: NbSidebarService,
    private layoutService: LayoutService,
    private themeService: NbThemeService,
    private route: Router,
    private loginService: LoginService,
    private sanitizer: DomSanitizer) {
      

  }
  societyCode
  userName
  loginId
  password
  ngOnInit() {
    var  payload = JSON.parse(sessionStorage.getItem('payload'));
    if(payload !=null){
      this.societyCode = payload.societyCode
      this.loginId = payload.loginId;
      this.userName = payload.userName;
      this.password = payload.password;
      this.currentTheme = this.themeService.currentTheme;
      //this.userName = sessionStorage.getItem('userName');
      // this.getCompanylogo(this.societyCode);
    }
    
     
  
    this.logout = this.menuService.onItemClick()
      .subscribe((event) => {
        this.logOut();
        if (event.item.title === 'Logout') {
          sessionStorage.clear();
          // console.log("logout");
          this.route.navigate(['/login']);
        }
      });
  }
  logOut(){
    // console.log(" in logout");
    this.loginService.logOut(this.societyCode,this.loginId,this.password).subscribe(
      // data=>console.log(data), error => console.error(error)
    )
  }

  ngOnDestroy() {
    this.logout.unsubscribe();
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }
  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
  img
  getCompanylogo(companyId){
    var companyLogo
    // this.loginService.getCompanyLogo(companyId).subscribe((
    //   response =>{
    //     companyLogo = response
    //     this.name = companyLogo.name
    //     let objectURL = 'data:image/jpeg;base64,' + companyLogo.image;
    //     Globle.imageBlobUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);  
    //     this.img = Globle.imageBlobUrl
    //   }
    // ))
  }
  
}
